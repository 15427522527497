.d-flex {
  display: flex !important;
}
.flex-1 {
  flex: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-bold {
  font-weight: bold;
}
.w-min-150 {
  min-width: 150px;
}
.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50% !important;
}
.w-30 {
  width: 30% !important;
}
.border-right {
  border-right: solid 1px;
}
.text-error {
  color: $error;
}
.point-cursor {
  cursor: pointer;
}
.white-space-pre-line {
  white-space: pre-line;
}

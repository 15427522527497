.order-list-status {
  display: flex;
  align-items: center;
  .order-list-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 6px;
    border-radius: 50%;
  }
}

.ant-picker-clear {
  display: none;
}

.order-form {
  background-color: #F5F5F5;
  label {
    font-weight: 400;
    font-size: 14px;
    height: 40px;
    color: #252525;
  }
  .filter-group {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .order-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 14px;
      line-height: 22px;
      color: #252525
    }
    .description {
      font-size: 12px;
      line-height: 20px;
      color: #888888;
    }
  }
  .ant-switch-checked {
    background-color: #1890FF;
  }
  &:focus,
  .ant-switch:focus {
    box-shadow: none;
  }
  .form-hidden {
    display: none;
  }
}

.order-table {
  .pc-only {
    display: inline-block;
  }
  .pc-col-only {
    display: table-cell;
  }
  .tablet-only {
    display: none;
  }
  .date-col {
    width: 15%;
  }
  .no-col {
    width: 12%;
  }
  .drink-col {
    width: 14%
  }
  .text-col {
    width: 17%;
  }
  .ant-table-selection-column {
    width: 6%;
  }
  .ant-table-cell {
    text-align: center !important;
  }
  .ant-table-content {
    table {
      table-layout: auto !important;
    }
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .order-table {
    .pc-only,
    .pc-col-only {
      display: none;
    }
    .tablet-only {
      display: block;
    }
    .date-col {
      width: 10%;
    }
    .no-col {
      width: 10%;
    }
    .drink-col {
      width: 11%
    }
    .text-col {
      width: 14%;
    }
    &.no-data {
      .ant-table table {
        table-layout: auto !important;
      }
    }
    .ant-table-tbody {
      .text-col {
        text-align: left !important;
      }
    }
    .ant-table-tbody > tr > td {
      padding: 10px;
    }
  }
  .order-form {
    .filter-group {
      .ant-form-item {
        margin-bottom: 0;
        margin-right: 16px;
      }
    }
  }
}

html, body, div, span, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, address,
del, em, img, small, strong, b, u, i, hr,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
button,
time, mark, audio, video {
  margin: 0;
  padding: 0;
}

.icon-user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    &:hover{
      cursor: pointer;
    }
    width: 30px;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .text-toggle {
    font-size: 12px;
  }
}

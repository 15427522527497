.ant-form {
  .ant-picker,
  .ant-select-lg {
    width: 210px;
  }
}
.ant-input-password-icon {
  width: 15px;
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .ant-form-item-control-input-content {
    display: flex;
  }
  .ant-form-inline {
    .ant-picker,
    .ant-select-lg,
    .ant-btn {
      max-width: 237px;
    }
  }
  .ant-form {
    display: flex;
    .ant-picker,
    .ant-select-lg {
      width: 130px;
    }
  }
  
}

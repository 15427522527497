.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: auto;
  max-width: 960px;
  &-small {
    max-width: 834px;
  }
}

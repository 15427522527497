$font-body: "Noto Sans CJK JP", "Noto Sans", sans-serif;
$font-size: (
  xs: 10px,
  sm: 12px,
  md: 14px,
  lg: 16px,
  xl: 18px,
  xxl: 20px,
);
$spacer: 20px !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: (
      $spacer * 0.75,
    ),
    4: $spacer,
    5: (
      $spacer * 1.25,
    ),
    6: (
      $spacer * 1.5,
    ),
    7: (
      $spacer * 1.75,
    ),
    8: (
      $spacer * 2,
    ),
  ),
  $spacers
);

$black: #000000;
$dark: #111111;
$white: #ffffff;
$white-1: #f0f0f0;
$gray-1: #f7f7f7;
$error: #ff4d4f;

$breakpoints: (
  small: 480px,
  medium: 768px,
  wide: 960px,
  large: 1024px,
  largest: 1280px
);
